import React from 'react';
import './home.css';
import { useNavigate } from 'react-router-dom';
import milanLogo from '../../assets/logo.webp';
import InforgraphicPdf from '../../assets/Lending-Infographic-02-09-25.pdf';

function Home() {
  let navigate = useNavigate();

  return (
    <div id='home' className='container text-center mt-5'>
      {/* <h1 className="mb-5">Milan Laser Clinic Apps</h1> */}
      <img
        className='mb-4'
        src={milanLogo}
        alt='Milan Logo'
        style={{ width: '200px' }}
      />

      <div
        className='btn btn-red mb-3 text-white text-uppercase d-block mx-auto'
        role='button'
        onClick={() => {
          navigate('/salespresentation');
        }}
      >
        Sales Presentation
      </div>

      <a
        className='btn btn-red my-3 text-white text-uppercase d-block mx-auto'
        href='https://qc.milanlaser.com/'
        role='button'
      >
        Quote Calculator
      </a>

      <a
        className='btn btn-blue my-3 text-white text-uppercase d-block mx-auto'
        href='https://survey.milanlaser.com/'
        role='button'
      >
        Client Satisfaction Survey
      </a>

      {/* <a className="btn btn-blue my-3 text-white text-uppercase d-block mx-auto" href="https://upsell.milanlaser.com/" role="button">
        Commission calculator
      </a> */}
      <div
        className='btn btn-blue my-3 text-white text-uppercase d-block mx-auto'
        role='button'
        onClick={() => {
          navigate('/upsell');
        }}
      >
        Commission Calculator
      </div>

      {/* <a className="btn btn-blue my-3 text-white text-uppercase d-block mx-auto" href="https://storerank.milanlaser.com/" role="button">
        Store Rank Calculator
      </a> */}
      <div
        className='btn btn-blue my-3 text-white text-uppercase d-block mx-auto'
        role='button'
        onClick={() => {
          navigate('/storerankcalculator');
        }}
      >
        Store Rank Calculator
      </div>

      <a
        className='btn btn-blue my-3 text-white text-uppercase d-block mx-auto'
        href={InforgraphicPdf}
        target='_blank'
        rel='noreferrer'
        // download
      >
        Lending Infographic
      </a>

      <div
        className='btn btn-navy mb-3 text-white text-uppercase d-block mx-auto'
        role='button'
        onClick={() => {
          navigate('/salespresentation30');
        }}
      >
        Sales Presentation: Pilot
      </div>
    </div>
  );
}

export default Home;
